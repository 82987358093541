import React from 'react'
import Helmet from 'react-helmet'
import { withPrefix } from 'gatsby'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import { LargeP, Button, DownloadButton, FlexList, FlexItem } from '../elements'

const CarriersOwnerOperatorsPage = ({ data }) => {
  const postNode = {
    title: `Carriers & Owner Operators - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`Carriers & Owner Operators - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <ServiceContainer>
        <PageTitle>Carriers & Owner Operators</PageTitle>
        <LargeP>
          As an asset based Transportation Company, we understand your needs as
          a carrier and we fulfill all of your transportation needs.
        </LargeP>
        <p>
          At D&D, we provide you a vast array of aggressively discounted
          programs and great freight rates. We have an extensive base of great
          customers to address all your transportation needs. We offer you
          security and peace of mind. Our team can guarantee capacity year round
          and we run you just as we run our asset-based fleet. Let us find your
          freight, load you from point to point, dispatch, do your billing and
          administrative work, while you collect a weekly settlement check. We
          welcome you to join our fleet.
        </p>
        <Button
          modifiers={['outline']}
          to="/contact"
          style={{ marginBottom: '1rem' }}
        >
          Get Started, Fill Out Our Contact Form&nbsp;
          <i className="material-icons">chevron_right</i>
        </Button>
        <h4>Dedicated Carriers</h4>
        <p>
          If you have one truck or you have 20 trucks and your own authority, we
          welcome you to join our fleet of dedicated carriers. As an asset based
          Transportation Company, we understand your needs as a carrier and we
          fulfill all of your transportation needs.
        </p>

        <h4>Owner Operators</h4>
        <p>
          D&D partners with our owner operators to create a professional
          business environment. We welcome owner operators to our fleet. Your
          truck is moved though our company dispatch system where it is viewed
          and handled as if it were a company truck. You are assigned a
          dedicated customer service specialist that will work with you directly
          to meet all your trucking needs. We have an abundance of freight
          waiting for you.
        </p>
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default CarriersOwnerOperatorsPage
